import React from "react";
import styled from "styled-components";
import Section from "../_global/layout/Section";
import Text from "../_global/editorial/Text";
import Spacer from "../_global/layout/Spacer";
import Conditional from "../_global/layout/Conditional";

const Comp = styled(Section)`
  align-items: center;
`;

const Points = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 26rem;
	flex: 1;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    margin-bottom: ${({ theme }) => theme.margins.small.l};
  }
`;

const MissionText = styled(Text)`
  //white-space: pre-wrap;
`;

const Mission = (props) => {
	const { data } = props;

	const points = data.points.map((item, index) => {
		return (
			<React.Fragment key={item.number}>
				<Point>
					<Text s="h1" c="main02" as="h1">
						{item.number}
					</Text>

					<Conditional desktop>
						<Spacer s="medium" />
					</Conditional>
					<Conditional mobile>
						<Spacer s="small" />
					</Conditional>

					<MissionText s="medium">
						{item.text}
					</MissionText>
				</Point>
				{index < data.points.length - 1 ?
					<>
						<Conditional desktop>
							<Spacer s="large" />
						</Conditional>
						<Conditional mobile>
							<Spacer s="small" />
						</Conditional>
					</>
					: null}
			</React.Fragment>
		);
	});

	return (
		<Comp m="small">
			<Text s="h2" as="h2" c="main01">
				{data.title}
			</Text>

			<Conditional desktop>
				<Spacer s="large" />
			</Conditional>
			<Conditional mobile>
				<Spacer s="small" />
			</Conditional>

			<Points>
				{points}
			</Points>

		</Comp>
	);
};

export default Mission;