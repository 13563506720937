import * as React from "react";
import localize from "../components/_global/localization/localize";
import { graphql } from "gatsby";
import Splash from "../components/1_home/Splash";
import Layout from "../components/_global/layout/Layout";
import Mission from "../components/1_home/Mission";
import Spacer from "../components/_global/layout/Spacer";
import NewsletterSection from "../components/_global/NewsletterSection";
import Conditional from "../components/_global/layout/Conditional";
import DonateComponent from "../components/1_home/DonateComponent";
import VolunteerSection from "../components/_global/VolunteerSection";
import Section from "../components/_global/layout/Section";
import NewsComponent from "../components/1_home/NewsComponent";
// import HomeLearn from "../components/1_home/HomeLearn";

// markup
const IndexPage = ({ data, ...props }) => {
    const {
        volunteer,
        general
    } = data;
    const homeData = data.sanityHome;
    const newsletterData = data.sanityNewsletterComponent;
    const donateData = data.sanityDonateComponent;

    return (
        <Layout title={homeData.common.title}>
            <Splash data={homeData.splash} />
            <Spacer s="large" />

            <Mission data={homeData.missionPoints} />

            <Conditional desktop>
                <Spacer s="larger" />
            </Conditional>

            <NewsComponent
                data={homeData.actions}
                button={homeData.actionsButton}
                more={general.learnMore}
            />
            <Spacer s="larger" />

            <Section m="small">
                {/*<HomeLearn*/}
                {/*	title={homeData.learnTitle}*/}
                {/*	data={homeData.learnItems}*/}
                {/*	more={general.learnMore}*/}
                {/*	buttons={[homeData.discoverButton, homeData.learnButton]}*/}
                {/*/>*/}
                {/*<Spacer s="large" />*/}

                <DonateComponent data={donateData} />
                <Spacer s="large" />

                <VolunteerSection data={volunteer} />
                <Spacer s="large" />

                <NewsletterSection data={newsletterData} />
                <Spacer s="large" />
            </Section>
        </Layout>
    );
};

export default localize(IndexPage);

export const query = graphql`
    {
        sanityHome {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            splash {
                image {
                    altText {
                        _type
                        fr
                    }
                    image {
                        asset {
                            gatsbyImageData(fit: CLIP, placeholder: DOMINANT_COLOR)
                        }
                    }
                }
                #                button {
                #                    ...LinkFragment
                #                }
                text {
                    _type
                    _rawFr(resolveReferences: {maxDepth: 20})
                }
            }
            missionPoints {
                points {
                    number
                    text {
                        _type
                        fr
                    }
                }
                title {
                    _type
                    fr
                }
            }
            actions {
                title {
                    _type
                    fr
                }
                bigActions {
                    ... on SanityEvent {
                        date(locale: "FR")
                        description {
                            _type
                            fr
                        }
                        dateType,
                        image {
                            ...ImageFragment
                        }
                        slug {
                            current
                        }
                        title {
                            _type
                            fr
                        }
                        internal {
                            type
                        }
                    }
                      ... on SanityMeeting {
                        date(locale: "FR")
                        description {
                            _type
                            fr
                        }
                        dateType,
                        image {
                            ...ImageFragment
                        }
                        slug {
                            current
                        }
                        title {
                            _type
                            fr
                        }
                        internal {
                            type
                        }
                    }
                      ... on SanityWorkshop {
                        date(locale: "FR")
                        description {
                            _type
                            fr
                        }
                        dateType,
                        image {
                            ...ImageFragment
                        }
                        slug {
                            current
                        }
                        title {
                            _type
                            fr
                        }
                        internal {
                            type
                        }
                    }
                }
                smallerActions {
                    ... on SanityEvent {
                        date(locale: "FR")
                        description {
                            _type
                            fr
                        }
                        dateType,
                        image {
                            ...ImageFragment
                        }
                        slug {
                            current
                        }
                        title {
                            _type
                            fr
                        }
                        internal {
                            type
                        }
                    }
                      ... on SanityMeeting {
                        date(locale: "FR")
                        description {
                            _type
                            fr
                        }
                        dateType,
                        image {
                            ...ImageFragment
                        }
                        slug {
                            current
                        }
                        title {
                            _type
                            fr
                        }
                        internal {
                            type
                        }
                    }
                      ... on SanityWorkshop {
                        date(locale: "FR")
                        description {
                            _type
                            fr
                        }
                        dateType,
                        image {
                            ...ImageFragment
                        }
                        slug {
                            current
                        }
                        title {
                            _type
                            fr
                        }
                        internal {
                            type
                        }
                    }
                }
            }
            actionsButton {
                ...LinkFragment
            }
            learnTitle {
                _type
                fr
            }
            learnItems {
                description {
                    _type
                    fr
                }
                image {
                    ...ImageFragment
                }
                slug {
                    current
                }
                title {
                    _type
                    fr
                }
            }
            learnButton {
                ...LinkFragment
            }
            discoverButton {
                ...LinkFragment
            }

        }
        general: sanityGeneralComponent {
            learnMore {
                _type
                fr
            }
        }
        sanityDonateComponent {
            ...DonateComponentFragment
        }
        volunteer: sanityVolunteerComponent {
            ...VolunteerComponentFragment
        }
        sanityNewsletterComponent {
            button {
                ...LinkFragment
                #                title {
                #                    _type
                #                    fr
                #                }
                #                linkType
                #                _rawInternalLink(resolveReferences: {maxDepth: 1})
            }
            text {
                _type
                fr
            }
            title {
                _type
                fr
            }
        }
    }
`;
