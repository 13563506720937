import React from "react";
import styled from "styled-components";
import Text from "./editorial/Text";
import Spacer from "./layout/Spacer";
import RoundButton from "./RoundButton";

const Comp = styled.div`
  background-color: ${({ theme }) => theme.colors.titanWhite};
  // width: 100%;
    // margin-left: ${({ theme }) => theme.margins.small.l};
    // margin-right: ${({ theme }) => theme.margins.small.l};
  padding-left: ${({ theme }) => theme.margins.medium.l};
  padding-right: ${({ theme }) => theme.margins.medium.l};
	display: flex;
	flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    // width: auto;
      // margin-left: ${({ theme }) => theme.margins.small.s};
      // margin-right: ${({ theme }) => theme.margins.small.s};
    padding-left: ${({ theme }) => theme.margins.medium.s};
    padding-right: ${({ theme }) => theme.margins.medium.s};
  }
`;

const TheButton = styled(RoundButton)`
  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
		align-self: center;
  }
`;

const NewsletterSection = (props) => {
	const { title, text, button } = props.data;

	return (
		<Comp>
			<Spacer s="large" />
			<Text s="h2" as="h1" c="main01">
				{title}
			</Text>
			<Spacer s="small" />

			<Text s="medium">
				{text}
			</Text>
			<Spacer s="large" />

			<TheButton data={button} type="pill" />
			<Spacer s="large" />
		</Comp>
	);
};

export default NewsletterSection;