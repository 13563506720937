import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Text from "./editorial/Text";
import Spacer from "./layout/Spacer";
import AltImage from "./AltImage";
import RoundButton from "./RoundButton";

const Content = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
	display: flex;
	flex-direction: column;
`;

const Button = styled(RoundButton)`

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    align-self: center;
  }
`;

const VolunteerSection = (props) => {
	const { data } = props;
	return (
		<>
			<Text s="h2" c="main01" a="center">
				{data.title}
			</Text>
			<Spacer s="medium" />

			<Content>
				<AltImage data={data.image} />

				<Spacer s="medium" />

				<TextWrapper>
					<Text s="medium" w="bold">
						{data.subTitle}
					</Text>

					<Text s="medium">
						{data.text}
					</Text>

					<Spacer s="medium" />

					<Button data={data.button} type="pill" />
				</TextWrapper>

			</Content>

		</>
	);
};

export default VolunteerSection;

export const query = graphql`
    fragment VolunteerComponentFragment on SanityVolunteerComponent {
        image {
            ...ImageFragment
        }
        button {
            ...LinkFragment
        }
        subTitle {
            _type
            fr
        }
        text {
            _type
            fr
        }
        title {
            _type
            fr
        }
    }
`;
