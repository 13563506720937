import React from "react";
import styled from "styled-components";
import Spacer from "../_global/layout/Spacer";
import Text from "../_global/editorial/Text";
import { graphql } from "gatsby";
import RoundButton from "../_global/RoundButton";

const Comp = styled.div`
  background-color: ${({ theme }) => theme.colors.titanWhite};
  // width: 100%;
  // margin-left: ${({ theme }) => theme.margins.small.l};
  // margin-right: ${({ theme }) => theme.margins.small.l};
  padding-left: ${({ theme }) => theme.margins.medium.l};
  padding-right: ${({ theme }) => theme.margins.medium.l};
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
  //   width: auto;
  //   margin-left: ${({ theme }) => theme.margins.small.s};
  //   margin-right: ${({ theme }) => theme.margins.small.s};
    padding-left: ${({ theme }) => theme.margins.medium.s};
    padding-right: ${({ theme }) => theme.margins.medium.s};
  }
`;

const TheButton = styled(RoundButton)`
  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
		align-self: center;
  }
`;

const DonateComponent = (props) => {
	const { data } = props;
	const button = {
		title: data.donateButton,
		linkType: "internal",
		internalLink: {
			common: {
				link: "donate"
			}
		}
	};

	return (
		<Comp>
			<Spacer s="large" />
			<Text s="h2" as="h1" c="main01">
				{data.title}
			</Text>
			<Spacer s="small" />

			<Text s="medium">
				{data.text}
			</Text>
			<Spacer s="large" />

			<TheButton data={button} type="pill"/>
			<Spacer s="large" />
		</Comp>
	);
};

export default DonateComponent;

export const query = graphql`
    fragment DonateComponentFragment on SanityDonateComponent {
        title {
            _type
            fr
        }
        donateButton {
            _type
            fr
        }
        currency
        regularDonation {
            _type
            fr
        }
        text {
            _type
            fr
        }
        otherValue {
            _type
            fr
        }
        oneTimeDonation {
            _type
            fr
        }
        donationValues
    }
`;