import React from "react";
import styled from "styled-components";
import RoundButton from "../_global/RoundButton";
import Text from "../_global/editorial/Text";
import Spacer from "../_global/layout/Spacer";
import ItemPreview from "../_global/lists/ItemPreview";
import Section from "../_global/layout/Section";

const Comp = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.titanWhite};
  display: flex;
  flex-direction: column;
	align-items: center;
`;

const BigActions = styled(Section)`
`;

const SmallActions = styled(Section)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  width: 100%;
	
	@media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    display: flex;
    flex-direction: column;
	}
`;

const NewsComponent = (props) => {
	const { data, button, more } = props;

	const bigActions = data.bigActions.map((item, index) => {
		const baseLink = item.internal.type === "SanityEvent" ? "agenda" : item.internal.type === "SanityWorkshop" ? "workshops" : item.internal.type === "SanityMeeting" ? "meetings" : "agenda"
		return (
			<React.Fragment key={index}>
				<ItemPreview news desc right={!index % 2} data={item} more={more} baseLink={baseLink} />
				{index < data.bigActions.length - 1 ?
					<Spacer s="large" />
					: null
				}
			</React.Fragment>
		);
	});

	const smallActions = data.smallerActions.map((item, index) => {
		const baseLink = item.internal.type === "SanityEvent" ? "agenda" : item.internal.type === "SanityWorkshop" ? "workshops" : item.internal.type === "SanityMeeting" ? "meetings" : "agenda"
		return (
			<React.Fragment key={index}>
				<ItemPreview col news data={item} more={more} baseLink={baseLink} />
				{/*{index < data.bigActions.length ?*/}
				{/*	<Spacer s="small" />*/}
				{/*	: null*/}
				{/*}*/}
			</React.Fragment>
		);
	});

	return (
		<Comp>
			<Spacer s="large" />
			<Text s="h2" as="h2" c="main01" a="center">
				{data.title}
			</Text>
			<Spacer s="large" />

			<BigActions m="small">
				{bigActions}
			</BigActions>
			<Spacer s="larger" />

			<SmallActions m="small">
				{smallActions}
			</SmallActions>
			<Spacer s="large" />

			<RoundButton data={button} type="pill" />
			<Spacer s="large" />
		</Comp>
	);
};

export default NewsComponent;