import React from "react";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import Text from "../_global/editorial/Text";

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
	width: 80%;
	max-width: 67.5rem;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
		max-width: none;
    width: 100%;
  }
`;

const HighlightText = styled(Text)`
  background-color: ${({ theme }) => theme.colors.main02};
`;

const Block = (props) => {
	const serializers = {
		container: props => (<BlockContainer article>{props.children}</BlockContainer>),
		types: {
			block: props => {
				// console.log(props.node);
				return (
					<>
						<Text
							as={"h1"}
							s="h1"
						>
							{props.children}
						</Text>
					</>
				);
			}
		},
		marks: {
			strong: props => {
				return (
					<>
						<HighlightText s="h1" as="span">
							{props.children[0].trim()}
						</HighlightText>
						{" "}
					</>
				);
			}
		}
	};

	return (
		<BlockContent
			blocks={props.data}
			serializers={serializers}
		/>
	);
};

export default Block;