import React from "react";
import styled from "styled-components";
import SanityImage from "../_global/SanityImage";
import SplashBlock from "./SplashBlock";

const Comp = styled.div`
  position: relative;
  width: 100%;
  height: 30rem;
  color: ${({ theme }) => theme.colors.background};
	overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    height: 15rem;
  }
`;

const Image = styled(SanityImage)`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 150%;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //background-color: palegoldenrod;
  padding: ${({ theme }) => theme.margins.small.l};

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    padding: ${({ theme }) => theme.margins.small.s};
  }
`;

const Splash = (props) => {
	return (
		<Comp>
			<Image alt={props.data.image.altText} image={props.data.image.image} />

			<Content>
				<SplashBlock data={props.data.text} s="h1" />
				{/*{props.data.text}*/}
			</Content>
		</Comp>
	);
};

export default Splash;
